export const classes = [
  // {
  //   id: 'Four',
  //   title: 'Four',
  // },
  // {
  //   id: 'Five',
  //   title: 'Five',
  // },
  // {
  //   id: 'Ten',
  //   title: 'Ten',
  // },
  // {
  //   id: 'Eleven',
  //   title: 'Eleven',
  // },
  {
    id: 'SSC Batch',
    title: 'S.S.C',
  },
  // {
  //   id: 'HSC Batch',
  //   title: 'H.S.C Batch',
  // },
  {
    id: 'Eleven',
    title: 'HSC 1st Year',
  },
  {
    id: 'Twelve',
    title: 'HSC 2nd Year',
  },
  {
    id: 'HSC 2024',
    title: 'HSC 2024',
  },
  // {
  //   id: 'HSC 2024',
  //   title: 'HSC 2nd Year',
  // },
  // {
  //   id: 'S.S.C Model Test 2023',
  //   title: 'S.S.C Model Test 2023',
  // },
  // {
  //   id: 'H.S.C Admission Test 2022',
  //   title: 'H.S.C Admission Test 2022',
  // },
  // {
  //   id: 'H.S.C. Model Test 2023',
  //   title: 'H.S.C. Model Test 2023',
  // },
]

export const versions = [
  {
    id: 'Bangla Version',
    title: 'Bangla Version',
  },
  {
    id: 'English Version',
    title: 'English Version',
  },
]
export const groups = [
  {
    id: 'Science',
    title: 'Science',
  },
  {
    id: 'Arts',
    title: 'Arts',
  },
  {
    id: 'Commerce',
    title: 'Commerce',
  },
]
